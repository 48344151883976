<template>
  <UIButton class="button-primary text-large button-lg">
    <slot />
  </UIButton>
</template>
<style lang="scss" scoped>
.button-primary {
  @apply bg-sky-400;
  color: white;
  width: 100%;
  min-width: min(20rem, 80vw);
  min-height: clamp(3rem, 3vw, 4.5rem);

  @media (max-width: 578px) {
    min-width: fit-content;
    padding: 0.5rem 2rem;
  }
}
</style>
<style lang="scss">
.button-lg {
  min-width: min(20rem, 80vw);
  min-height: clamp(3rem, 3vw, 4.7rem);
}
</style>
